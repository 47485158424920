@keyframes slideUpwards {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(-5px);
    opacity: 1;
  }
}

.slideUpwards {
  top: 10px;
  left: 10px;
  animation: slideUpwards ease 0.5s forwards 1.2s;
}

.embla {
  overflow-x: hidden;
  overflow-y: visible;
  --slide-size: 100%;
}

.embla__container {
  display: flex;
}

.embla__slide { 
  cursor: move;
}